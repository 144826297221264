
import Vue, { VueConstructor } from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import DateFormatMixin from "@/mixins/DateFormatMixin.vue"
import UserMixin from "@/mixins/UserMixin.vue"

interface Log {
  id: number,
  space_id: number,
  user_id: number,
  firstname: string,
  lastname: string,
  type: string,
  title: string,
  description: string,
  created_at: string
  img?: string
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof UserMixin> & InstanceType<typeof DateFormatMixin>>).extend({
  name: "ActivityLogs",
  mixins: [
    DateFormatMixin,
    UserMixin
  ],
  props: {
    schema: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    logs: [] as Array<Log>,
    title: "",
    description: "",
    page: 1,
    userId: 0,
    intervalId: -1,
    loaders: {
      create: false,
      get: false
    },
    isLastPage: false
  }),
  computed: {
    sortedLogs () {
      let ret: Array<Log> = this.logs
      ret.sort((l1: Log, l2: Log) => {
        return -l1.created_at.localeCompare(l2.created_at)
      })
      return ret
    },
    requiredRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    isDisable() {
      return !((this.title && this.description ) && !this.loaders.create)
    }
  },
  methods: {
    // Method to get all logs
    getLogs(forcedPage: number | null = null) {
      const requestedPage = forcedPage ?? this.page
      if (forcedPage === null) {
        this.loaders.get = true
      }
      axios
        .get(`/v1/logs?page=${requestedPage}&schema=${this.schema}&id=${this.id}`)
        .then((response: AxiosResponse) => {
          // If last page is reach, remove "display more" button
          let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(response.headers.link)
          if (match && this.page >= parseInt(match[1])) {
            this.isLastPage = true
          }
          // add only new logs
          const newLogs = response.data.filter((eachLog: Log) => {
            for (const loadedLogs of this.logs) {
              if (loadedLogs.id == eachLog.id) {
                return false
              }
            }
            return true
          })
          this.logs = this.logs.concat(newLogs)
          // If first pull was successfull, set up page refetch every 5s
          if (this.intervalId === -1) {
            this.intervalId = setInterval(() => {
              this.getLogs(1)
            }, 5000)
          }
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        }).finally(() => {
          if (forcedPage === null) {
            this.loaders.get = false
          }
        })
    },
    // Method to create log
    createLog() {
      this.loaders.create = true
      axios
        .post("/v1/logs", {
          schema: this.schema,
          schema_id: this.id,
          title: this.title,
          description: this.description
        })
        .then(() => {
          // success snackbar
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.activity_logs.create_success') });
          // reset form
          (this.$refs.form as HTMLFormElement).reset()
          // get last log
          this.getLogs(1)
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        }).finally(() => {
          this.loaders.create = false
        })
    },
    deleteLog(log: Log) {
      axios
      .delete("/v1/logs", { params: { id: log.id } })
      .then(() => {
          // success snackbar
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.activity_logs.delete_success') })
          // remove deleted log
          this.logs = this.logs.filter((eachLog: Log) => eachLog !== log)
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    },
    displayLog() {
      this.page++
      this.getLogs()
    },
    isDeletable(log: Log) {
      return log.type === "User note" && log.user_id === this.userId
    },
    resetComponent() {
      if (this.intervalId !== -1) {
        clearInterval(this.intervalId)
        this.intervalId = -1
      }
      this.logs = []
      this.page = 1
      this.isLastPage = false
      this.getLogs()
    }
  },
  watch: {
    id: function() {
      this.resetComponent()
    },
    schema: function() {
      this.resetComponent()
    }
  },
  mounted() {
    this.userId = this.getUserId()
    this.getLogs()
  },
  beforeDestroy() {
    if (this.intervalId !== -1) {
      clearInterval(this.intervalId)
    }
  }
})
