
import Vue from "vue"
import ListParagon, { Filter } from "@/components/ListParagon.vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import { state } from "@/plugins/state"
import ClientsFormatter from "@/components/workers/ClientsFormatter.vue"

const URL = `/v1/workers?fields=
last_name,
first_name,
mobile_phone_number,
email,
profile_picture,
transportation_means,
favorite_jobs,
postal_city,
postal_code,
clients&deleted_at=false`

interface OrderInterface {
  started_at?: string,
  finished_at?: string,
  id: number,
  position_id?: number,
  force_available_workers?: number
}

interface PositionInterface {
  id: number,
  name: string,
  priority: number | null
  totalDelegated: number
}

interface ClientInterface {
  id: number,
  name: string,
  positions: PositionInterface[]
}

export default Vue.extend({
  name: 'WorkerMatching',
  props: {
    id: {
      type: Number,
      required: true
    },
    selection: {
      type: Object,
      default: null
    },
    emitSelection: {
      type: Boolean,
    }
  },
  components: {
    ListParagon,
    ClientsFormatter
  },
  data () {
    return {
      // show modal
      dialog: false,
      selectedItems: {},
      positionsFilter: 'associated' as string,
      order: {} as OrderInterface,
      selectionData: {},
      matching: false,
      loading: true,
      titleModal: "favorite_jobs",
      data: null,
      workableFilter: false,
      headers: [
        {
            text: this.$vuetify.lang.t("$vuetify.card"),
            sortable: true,
            value: 'card'
        },
        {
            text: this.$vuetify.lang.t("$vuetify.favorite_jobs"),
            sortable: true,
            value: 'favorite_jobs',
            sortText: this.$vuetify.lang.t("$vuetify.favorite_jobs_total")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.contact_details"),
            sortable: true,
            value: 'mobile_phone_number',
            sortText: this.$vuetify.lang.t("$vuetify.mobile_phone_number")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.recruitment"),
            sortable: true,
            value:'recruitment',
            sortText: this.$vuetify.lang.t("$vuetify.recruitment_total")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.order_priority"),
            sortable: true,
            value:'order_priority'
        },
      ],
      filters: [
        {label: 'names', type: 'text-field'},
        {label: 'email', type: 'text-field'},
        {label: 'mobile_phone_number', type: 'text-field'},
        {label: 'position_id', type: 'autocomplete', url:'/v1/clients/positions', text: 'name', value: 'position_id', search: 'name'},
        {label: 'favorite_jobs', type: 'text-field'},
        {label: 'postal_city_code', type: 'text-field'},
        {label: 'transportation_means', type: 'select',
        items:[
          {text: this.$vuetify.lang.t("$vuetify.car"), value: "car"},
          {text: this.$vuetify.lang.t("$vuetify.motorbike"), value: "motorbike"},
          {text: this.$vuetify.lang.t("$vuetify.bike_scooter"), value: "bike_scooter"},
          {text: this.$vuetify.lang.t("$vuetify.mass_transit"), value: "mass_transit"},
          {text: this.$vuetify.lang.t("$vuetify.moped"), value: "moped"},
          {text: this.$vuetify.lang.t("$vuetify.walk"), value: "walk"},
          {text: this.$vuetify.lang.t("$vuetify.carpool"), value: "carpool"}
        ],
        multiple: true},
      ] as Array<Filter>,
      select: null,
      disabled: true,
      reload: 0,
      statusArray: [
        {label: this.$vuetify.lang.t("$vuetify.order_status.select"), value: 1},
        {label: this.$vuetify.lang.t("$vuetify.order_status.in_progress"), value: 2},
        {label: this.$vuetify.lang.t("$vuetify.order_status.waiting"), value: 3},
        {label: this.$vuetify.lang.t("$vuetify.order_status.accepted"), value: 4},
        {label: this.$vuetify.lang.t("$vuetify.order_status.refused"), value: 5},
        {label: this.$vuetify.lang.t("$vuetify.order_status.cancelled"), value: 6},
      ],
      url: null as string | null
    }
  },
  mounted(){
    this.getOrder()
    this.selectionData = this.$props.selection
    this.selectedItems = this.selectionData
  },
  watch : {
    positionsFilter() {
      this.buildUrl()
    },
    workableFilter() {
      this.buildUrl()
    },
    selection(){
      this.selectionData = this.$props.selection
    },
    selectionData() {
      if (this.emitSelection) {
        this.reload++
      }
    }
  },
  methods: {
    // retrieve order's position priority
    // if worker is associated to the position
    getOrderPositionPriority(clients: ClientInterface[]): string {
      let priority = ""
      clients.forEach((client: ClientInterface) => {
        client.positions.forEach((position: PositionInterface) => {
          if (position.id == this.order.position_id && position.priority !== null) {
            priority = String(position.priority)
          }
        })
      })
      return priority
    },
    getOrder() {
      axios
      .get(`/v1/orders/${this.id}`)
      .then((response: AxiosResponse) => {
          this.order = response.data
          // retrieve matching forcing
          this.matching = Boolean(this.order.force_available_workers).valueOf()
          this.loading = false
          this.buildUrl()
        }).catch((error: AxiosError)=> {
          EventBus.$emit('snackbar',{axiosError: error})
          this.$router.push({ path: '/orders' })
        })
    },
    addOrders() {
      let body = []
      if (this.selectedItems) {
        for (const item of Object.keys(this.selectedItems)) {
          body.push({'worker_id': + item})
        }
        axios.post(`/v1/orders/${this.order.id}/workers`, body).then(()=> {
          // success snackbar
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.creation_success')})
          this.reloadData()
        }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
      }
    },
    reloadData() {
      // force refresh paragon
      this.$emit('addWorkers')
      this.reload++
      this.selectionData =this.selectedItems
    },
    checkLogo(name: string) {
      switch (name) {
        case 'bike_scooter':
          return 'bike'
        case 'mass_transit' :
          return 'bus'
        case 'carpool' :
          return 'car-2-plus'
        default:
          return name
      }
    },
    arraySelectedItems(selectParagon: Record<string, unknown>) {
      this.selectedItems = selectParagon
      if (this.emitSelection === false) {
        this.$emit('selectedWorkers', this.selectedItems)
      }
    },
    buildUrl() {
      // positions filter
      let positionsFilterUrl = ''
      if (this.positionsFilter == "unassociated") {
        positionsFilterUrl = `&positions=false:${this.order.position_id}`
      } else if (this.positionsFilter == "associated") {
        positionsFilterUrl = `&positions=true:${this.order.position_id}`
      }
      // workable filter
      let workableFilterUrl = ""
      if (this.workableFilter || this.matching) {
        workableFilterUrl = `&workable=true:${this.order.started_at},${this.order.finished_at}`
      }
      this.url = URL + `&order=false:${this.id}&space_id=${state.agency}` + positionsFilterUrl + workableFilterUrl
    },
    getClientPositions(data: Array<ClientInterface>) {
      let clients: string[] = []
      if (data) {
        data.forEach((client) => {
          client.positions.forEach((position: PositionInterface) => {
            clients.push(
              `${position.name} ${this.$vuetify.lang.t("$vuetify.at")} ${client.name}`
            )
          })
        })
      }
      return clients
    },
    openData(titleModal: string, data: any){
      this.dialog = true
      this.data = data
      this.titleModal = titleModal
    },
  }
})
