
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

import DateTimePicker from "./DateTimePicker.vue"
import { format } from "date-fns"

interface ArrayColor {
  [key: string]: string
}

interface OrderBodyType {
  order_template_id?: number
  space_id?: number,
  position_id?: number,
  address?: string,
  started_at?: string,
  finished_at?: string,
  quantity?: number,
  force_all_periods?: boolean,
  free_text?: string
}

interface Period {
  id: number,
  started_at: {
    dateObject: Date | null,
    dateString: string,
    timeString: string,
    updated?: boolean
  },
  finished_at: {
    dateObject: Date | null,
    dateString: string,
    timeString: string,
    updated?: boolean
  },
}

export default Vue.extend({
  name: 'OrderDetails',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    DateTimePicker,
  },
  data: () => ({
    clientName: "",
    positionName: "",
    address: "",
    quantity: 0 as number,
    contractType: null as string|null,
    disabled: true,
    disableForm: false,
    colors: {
      pending: "info",
      processing: "success",
      closed: "secondary",
      cancelled: "primary",
    } as ArrayColor,
    dataReference: {} as OrderBodyType,
    updated: false,
    freeText: "",
    periods: [] as Period[],
    deletePeriodDialog: false,
    canSendPeriods: false,
    forceAllPeriods: false,
    selectedPeriodId: 0,
    periodIndex: 0
  }),
  computed: {
    maxLengthRule(){
      return (v: string) => ((v.length <= 2500)) || this.$vuetify.lang.t('$vuetify.invalid_length')
    }
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    selectPeriod(periodId: number, index: number) {
      // get id and index of period into periods array
      this.selectedPeriodId = periodId
      this.periodIndex = index
    },
    updateOrder() {
      // Update order
      const body = this.buildUpdateBody()
      if (Object.keys(body).length !== 0) {
        axios
          .put(`/v1/orders`, {
            [this.id]: body
          })
          .then(() => {
            EventBus.$emit('snackbar', {message: this.$vuetify.lang.t('$vuetify.order_successfully_updated')})
            // We re-run the GET request so that the data is up to date
            this.getLogs()
            this.disabled = true
            this.updated = !this.updated
            this.$emit('orderUpdated', this.updated)
          })
          .catch((error: AxiosError) => {
            let type = ''
            // default the snackbar to error, overwrite if no errors are found
            let snackbarBody = {axiosError: error} as {[key:string]: any}
            if (error.response?.data?.includes("The quantity must be equal or greater than total delegated workers.")) {
              this.quantity = +(this.dataReference.quantity as number)
              type = 'quantity'
              snackbarBody = {message: this.$vuetify.lang.t('$vuetify.quantity_equal_or_greater_than_total'), "color": "error"}
            }
            EventBus.$emit('snackbar', snackbarBody)
            this.$emit('error', type)
          })
      }
      // Create/Update order periods
      if (this.canSendPeriods) {
        this.savePeriods()
      }
      // Display no modification detected if needed
      if ((Object.keys(body).length === 0) && !this.canSendPeriods) {
        EventBus.$emit('snackbar', {"message": this.$vuetify.lang.t('$vuetify.no_modification_detected'), "color": "error"})
      }
      this.disabled = true
    },
    async getLogs() {
      axios
        .get(`/v1/orders/${this.id}`)
        .then((response: AxiosResponse) => {
          this.dataReference = response.data

          this.clientName = response.data.client_name
          this.positionName = response.data.position_name

          this.address = response.data.address
          this.quantity = response.data.quantity

          this.freeText = response.data.free_text ?? ""
          this.contractType = response.data.template.contract_type
          this.forceAllPeriods = response.data.force_all_periods

          // We get the order's periods
          this.getPeriods()
        })
        .catch(async (error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    },
    getPeriods() {
      axios
        .get(`/v1/orders/${this.id}/periods`)
        .then((response: AxiosResponse) => {
          // We reset the field
          this.periods = []
          // We fill the periods with the order's periods
          for (const period of response.data) {
            this.periods.push(
              {
                id: period.id,
                started_at: {
                  dateObject: new Date(period.started_at),
                  dateString: period.started_at.slice(0, 10),
                  timeString: period.started_at.slice(11, 16)
                },
                finished_at: {
                  dateObject: new Date(period.finished_at),
                  dateString: period.finished_at.slice(0, 10),
                  timeString: period.finished_at.slice(11, 16)
                }
              }
            )
          }
        })
        .catch(async (error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    },
    /**
     * Add a new period to periods
     */
    addPeriod()
    {
      let period =
      {
        id: 0,
        started_at: {dateObject: new Date(), dateString: "", timeString: ""},
        finished_at: {dateObject: new Date(), dateString: "", timeString: ""}
      } as Period

      const now = new Date(period.started_at.dateObject as Date)
      now.setHours(now.getHours() + 8)
      period.finished_at.dateObject = now
      period.finished_at.dateString = format(now, "yyyy-MM-dd")
      period.finished_at.timeString = format(now, "HH:mm:ss").slice(0, 5)
      // We increment the period list inputs
      this.periods.push(
          period
      )
    },
    /**
     * Send the newly created and updated Periods to the API
     *
     */
    savePeriods(): void
    {
      let createPeriodsBody = []
      for (const period of this.periods) {
        // We store only the new periods
        if(period.id === 0) {
          createPeriodsBody.push(
            {
              started_at: `${period.started_at.dateString} ${period.started_at.timeString}:00`,
              finished_at: `${period.finished_at.dateString} ${period.finished_at.timeString}:00`,
            }
          )
        }
      }
      if (createPeriodsBody.length > 0) {
        // We create the new periods
        axios
        .post(`/v1/orders/${this.id}/periods`, createPeriodsBody)
        .then(() => {
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_successfully_updated')})
          this.getPeriods()
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error.response})
        })
      }
      // We get the periods that were locally updated
      let updatedPeriods = this.findUpdatedPeriods()
      if (updatedPeriods.length > 0){
        // We prepare the requests in an array
        let updatePromises = []
        for (const period of updatedPeriods) {
          let updatePeriodBody = {
            started_at: `${period.started_at.dateString} ${period.started_at.timeString}:00`,
            finished_at: `${period.finished_at.dateString} ${period.finished_at.timeString}:00`,
          }
          // if "permanent" contract_type, generate finished_at to one year after
          if (this.contractType === 'permanent') {
            // We get the startDate as base and add to it 1 year
            let nextYear = new Date(period.started_at.dateString)
            nextYear.setFullYear(nextYear.getFullYear() + 1)
            const endYear = nextYear.toISOString().split('T')[0]
            updatePeriodBody.finished_at = `${endYear} ${period.started_at.timeString}:00`
          }

          updatePromises.push(axios.put(`/v1/orders/${this.id}/periods/${period.id}`, updatePeriodBody))
        }

        Promise.all(updatePromises).then(() => {
            EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_successfully_updated')})
            this.getPeriods()
            this.$emit('periodsUpdate',true)
        });
      }
    },
    /**
     * Remove a period from periods
     * @param id the id of the order_period to remove
     * @param index the index of the period in the periods array
     *
     */
    removePeriod(): void
    {
      // We launch a request only if the period has an id > 0 (it's registered in DB)
      if(this.selectedPeriodId !== 0) {
        axios.delete(`/v1/orders/${this.id}/periods/${this.selectedPeriodId}`)
        .then(() => {
          this.getPeriods()
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_period_successfully_deleted')})
          this.$emit('periodsUpdate', true)
        }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
      } else { // Else we just remove it from the period list
        this.periods.splice(this.periodIndex, 1)
      }
      this.deletePeriodDialog = false
    },
    /**
     * Search in order's periods the one that have been updated
     *
     * @returns an array of Period which have been updated
     */
    findUpdatedPeriods(): Period[]
    {
      let updatedPeriods = []
      // tag as updated already existing periods
      for (const period of this.periods) {
        if (period.id && (period.started_at?.updated || period.finished_at?.updated)) {
          updatedPeriods.push(period)
        }
      }
      return updatedPeriods
    },
    buildUpdateBody(): OrderBodyType
    {
      let body = {} as OrderBodyType
      if (this.dataReference.address !== this.address) body.address = this.address
      if (this.dataReference.free_text !== this.freeText) body.free_text = this.freeText
      if (Number(this.dataReference.quantity) !== this.quantity) body.quantity = this.quantity
      if (this.dataReference.force_all_periods !== this.forceAllPeriods) body.force_all_periods = this.forceAllPeriods


      let periodErrorIndexes = []
      for (let i = 0; i < this.periods.length; i++) {
        let startingAt = `${this.periods[i].started_at.dateString} ${this.periods[i].started_at.timeString}:00`
        let finishedAt = `${this.periods[i].finished_at.dateString} ${this.periods[i].finished_at.timeString}:00`
        // If finished_at is set before starting_at and for a non permenant order
        if((Date.parse(startingAt) > Date.parse(finishedAt)) && this.contractType !== 'permanent') {
          periodErrorIndexes.push(i+1)
        }
      }

      if (periodErrorIndexes.length > 0) {
        EventBus.$emit('snackbar',{message:`${this.$vuetify.lang.t('$vuetify.period_chronology_error')}: ${periodErrorIndexes.join(", ")} `, color: "error"})
        // I break the function here because we can't have multiple snackbars on screen.
        // If I don'"t break here, the 'no modification snackbar' takes over the chronologic errors one"
        return {}
      } else if (this.findUpdatedPeriods().length !== 0 || this.periods[this.periods.length-1].id === 0) {
        // If updated periods or new periods are found
        this.canSendPeriods = true
      }

      return body
    }
  }
})
