
import Vue from "vue"
import ActivityLogs from "@/components/ActivityLogs.vue"
import OrderDetails from "@/components/OrderDetails.vue"
import ValidatedWorkerList from "@/components/ValidatedWorkerList.vue"
import WorkerFollowUp from "@/components/order/WorkerFollowUp.vue"
import WorkerMatching from "@/components/order/WorkerMatching.vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

export default Vue.extend({
    components: {
        ActivityLogs,
        OrderDetails,
        ValidatedWorkerList,
        WorkerFollowUp,
        WorkerMatching
    },
    data: () => ({
      tab: 0,
      orderUpdateEvent: false,
      errorQuantityEvent: false,
      reload: 0,
      update:0,
      selectionForSelection: {},
      selectionForMatching:{},
      fromMatching: false,
      fromSelection: false,
      order: {
        client_name: "",
        position_name: "",
        template: {
            contract_type: ''
        }
      }
    }),
    mounted() {
        // get order
        axios.get(`/v1/orders/${this.$route.params.id}`)
        .then((response: AxiosResponse) => {
            this.order = response.data
        })
        .catch(async (error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
          this.$router.push({ path: '/orders' })
        })

        // redirection on right selection tab if requested
        if (this.$route.params.order_template_status_id) {
            // show worker selection tab
            this.tab = 1;
            // select order_worker_status.id from url
            let interval = setInterval(() => {
                if (this.$refs.WorkerFollowUp) {
                    (this.$refs.WorkerFollowUp as InstanceType<typeof WorkerFollowUp>).selectedOrderTemplateStatusId = Number(this.$route.params.order_template_status_id)
                    clearInterval(interval)
                }
            }, 250)
        }
    },
    watch: {
        tab() {
            switch (this.tab) {
                case 1:
                    this.fromSelection = false
                    this.fromMatching = !this.fromSelection
                    break
                case 2:
                    this.fromSelection = true
                    this.fromMatching = !this.fromSelection
                    break
                default:
                    this.fromSelection = this.fromMatching = false
                    break
            }
        }
    },
    methods: {
        previousSelection(selectParagon: Record<string, unknown>, origin: string){
            if (origin === 'selection') {
                this.selectionForMatching = selectParagon
                this.fromSelection = true
                this.fromMatching = !this.fromSelection
            } else {
                this.selectionForSelection = selectParagon
                this.fromMatching = true
                this.fromSelection = !this.fromMatching
            }
        },
        reloadUpdate(){
            this.update++
        },
        orderUpdated(event : boolean) {
            // we refetch order on matching component
            // in order to propagate eventual
            // workable period modifications
            if (this.$refs.matching) {
                (this.$refs.matching as InstanceType<typeof WorkerMatching>).getOrder()
            }
            this.orderUpdateEvent = event
        },
        redirection(quantityError: boolean) {
            if (quantityError) {
                this.tab = 1
                this.errorQuantityEvent = true
            }
            this.reload ++
        }
    }
})
